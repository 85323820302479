<template>
    <v-card elevation="0">
        <v-card-title v-if="oidcIsAuthenticated">
            {{oidcUser.name}}
            <v-spacer></v-spacer>
            <v-btn @click="signOutOidc" elevation="0">
                <v-icon small>mdi-logout-variant</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text v-if="!oidcIsAuthenticated">
            <v-btn @click="authenticateOidc" block>
                <v-icon small>mdi-login-variant</v-icon>
                <v-spacer></v-spacer>
                Log In
            </v-btn>
        </v-card-text>
    </v-card>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex';

    export default {
        name: "UserinfoCard",
        computed: {
            ...mapGetters('oidcStore', [
                'oidcIsAuthenticated',
                'oidcUser',
                'oidcAccessToken',
                'oidcAccessTokenExp',
                'oidcIdToken',
                'oidcIdTokenExp',
                'oidcRefreshToken',
                'oidcRefreshTokenExp',
                'oidcAuthenticationIsChecked',
                'oidcError'
            ])
        },
        methods: {
            ...mapActions('oidcStore', [
                'authenticateOidc', // Authenticates with redirect to sign in if not signed in
                'oidcSignInCallback', // Handles callback from authentication redirect. Has an optional url parameter
                'authenticateOidcSilent', // Authenticates if signed in. No redirect is made if not signed in
                'getOidcUser', // Get user from oidc-client storage and update it in vuex store. Returns a promise
                'signOutOidc', // Signs out user in open id provider
                'signOutOidcSilent', // Signs out user in open id provider using a hidden iframe
                'removeOidcUser' // Signs out user in vuex and browser storage, but not in open id provider
            ])
        },
    }
</script>

<style scoped>

</style>
