<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" app>
      <v-system-bar></v-system-bar>
        <UserinfoCard></UserinfoCard>
      <v-divider></v-divider>
      <v-list nav dense>
        <v-subheader>Entitäten</v-subheader>
        <v-list-item-group color="primary">
        <v-list-item v-for="(item, i) in links" :key="i" link :to="item.to">
          <v-list-item-content>
            {{item.label}}
          </v-list-item-content>
        </v-list-item>
        </v-list-item-group>
      </v-list>
      <!--  -->
    </v-navigation-drawer>

    <v-app-bar app collapse-on-scroll elevation="1">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>xDSLVergleich Admin</v-toolbar-title>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import UserinfoCard from '@/components/UserinfoCard';

export default {
  name: "MainLayout",
    components: {UserinfoCard},
    computed: {
    ...mapGetters('oidcStore', [
        'oidcIsAuthenticated',
        'oidcUser',
        'oidcAccessToken',
        'oidcAccessTokenExp',
        'oidcIdToken',
        'oidcIdTokenExp',
        'oidcRefreshToken',
        'oidcRefreshTokenExp',
        'oidcAuthenticationIsChecked',
        'oidcError'
    ])
  },
    methods: {
    ...mapActions('oidcStore', [
      'authenticateOidc', // Authenticates with redirect to sign in if not signed in
      'oidcSignInCallback', // Handles callback from authentication redirect. Has an optional url parameter
      'authenticateOidcSilent', // Authenticates if signed in. No redirect is made if not signed in
      'getOidcUser', // Get user from oidc-client storage and update it in vuex store. Returns a promise
      'signOutOidc', // Signs out user in open id provider
      'signOutOidcSilent', // Signs out user in open id provider using a hidden iframe
      'removeOidcUser' // Signs out user in vuex and browser storage, but not in open id provider
    ])
  },
  data: () => ({
    drawer: false,
    links: [
      {label: 'Anbieter', to: '/anbieter'},
      {label: 'Tarife', to: '/tarife'},
    ]
  }),
};
</script>
